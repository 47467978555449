import React from "react";
import { Box, Flex, Link } from "rebass/styled-components";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Main from "../components/main";
import Text from "../components/text";
import { H1 } from "../components/headers";
import { BorderedBox } from "../components/boxes";

const Help = () => (
  <>
    <SEO title="Help &amp; Support" />
    <Layout>
      <Main p={[3, 5, 5]} mb={4}>
        <Flex flexWrap="wrap">
          <Box p={3} width={[1, 1 / 2, 1 / 2]}>
            <H1 mb={3}>help &amp; support</H1>
            <Text color="white" mb={2}>
              You can send us a message in the app, or email us if you prefer.
            </Text>
            <Text color="white" mb={2}>
              We operate a support function during office hours Monday - Friday
              09:00 - 17:00. Please allow 24hrs for a response Monday - Friday
              and 48hrs Saturday and Sunday.
            </Text>
            <Text color="white" mb={2}>
              Thank you, The Manage My Meds team.
            </Text>
          </Box>
          <Box p={3} width={[1, 1 / 2, 1 / 2]}>
            <BorderedBox>
              <Text color="white">Email us</Text>
              <Link
                variant="bigLink"
                href="mailto:managemymeds@avicenna.org"
                color="white"
              >
                managemymeds@avicenna.org
              </Link>
            </BorderedBox>
          </Box>
        </Flex>
      </Main>
    </Layout>
  </>
);

export default Help;
