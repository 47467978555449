import React from "react";
import { Box, BoxProps } from "rebass/styled-components";

export const BorderedBox = (props: BoxProps) => (
  <Box
    p={3}
    {...(props as any)}
    sx={{
      border: "2px solid",
      borderColor: "primaryLight",
      borderRadius: "medium",
      ...props.sx
    }}
  >
    {props.children}
  </Box>
);

export const SolidBox = (props: BoxProps) => (
  <Box
    p={3}
    {...(props as any)}
    sx={{
      borderRadius: "medium",
      ...props.sx
    }}
  >
    {props.children}
  </Box>
);